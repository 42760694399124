'use client'

import { Device, DeviceInfo } from '@capacitor/device'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface IApplicationConfigsContext {
  width: number
  height: number
  deviceInfo: DeviceInfo | undefined
}

export const ApplicationConfigsContext = createContext<IApplicationConfigsContext | null>(null)

export default function ApplicationConfigsProvider({ children }: { children: ReactNode }) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>(undefined)

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
      getDeviceInfo()
    }
  }, [])

  const getDeviceInfo = async () => {
    const info = await Device.getInfo()
    setDeviceInfo(info)
  }

  const context = { width, height, deviceInfo }

  return <ApplicationConfigsContext.Provider value={context}>{children}</ApplicationConfigsContext.Provider>
}

export const useApplicationConfigsState = () => {
  const applicationConfigsState = useContext(ApplicationConfigsContext)
  if (!applicationConfigsState) throw new Error("Can't call useApplicationConfigsState before context is loaded")

  return applicationConfigsState
}
