// these types are duplicated in both functions and site
// they really should be in a 3rd project in the monorepo
// and shared between both.  But the firebase deploy
// requires all the code to be in the project folder.
// I also tried to just require the functions module from
// the web module, but firebase-admin doesn't like being
// webpacked.

import { TUserTakes } from '.'

////////////////////////////////////////////////////////////////////////
// Firestore Types
//
// These types represent the schema of the records stored in Firestore
// and should be used when creating or reading data in Firestore
////////////////////////////////////////////////////////////////////////

export type AddressInformation = {
  firstName: string
  lastName: string
  address: string
  addressEtc?: string
  city: string
  state: string
  postalCode: string
}

export type User = {
  id: string
  createdAt: Date
  labels?: string[]
  email?: string
  emailVerified?: boolean
  displayName?: string
  phoneNumber?: string
  address?: AddressInformation
  messagingRegistrationTokens?: MessagingRegistrationToken[]
  notificationMethodPreference?: UserNotificationMethodPreference
  preferences?: string[]
  hasCustomFeed: boolean
  referralCode: string
  referralCodeQRCode?: string
  referredBy?: string
  rewardsBalance: number
  lifetimeRewards: number
  appSource: UserAppSource
  birthYear?: string
  gender?: string
}

export type UserNotificationMethodPreference = {
  text: boolean
  email: boolean
  push: boolean
  shadedTopicsForYou: boolean
  developingTopicsForYou: boolean
  updatedTopics: boolean
  newGamesAndFeatures: boolean
}

export type MessagingRegistrationToken = {
  token: string
  timestamp: Date
}

export enum UserAppSource {
  iOS = 'ios',
  web = 'web',
  unknown = 'unknown',
}

export type RewardTransaction = {
  id: string
  userId: string
  timestamp: Date
  type: RewardTransactionType
  amount: number
}

export type RedeemItemTransaction = {
  id: string
  userId: string
  timestamp: Date
  redeemedItem: { title: string; points: number }
  address: AddressInformation
}

export enum RewardTransactionType {
  Referral = 'referral',
  Referred = 'referred',
  ReadATile = 'read_a_tile',
  Share = 'share',
  CreateAThought = 'create_a_thought',
  PlayAGame = 'play_a_game',
  CompleteAccount = 'complete_account',
  EnableNotifications = 'enable_notifications',
  FollowTikTok = 'follow_tiktok',
  FollowIG = 'follow_ig',
  RedeemItem = 'redeem_item',
}

export enum RewardTransactionTypeAmount {
  Referral = 100,
  ReadATile = 1,
  Share = 5,
  CreateAThought = 5,
  PlayAGame = 1,
  CompleteAccount = 10,
  EnableNotifications = 10,
  FollowTikTok = 10,
  FollowIG = 10,
}

export type ContactList = {
  userId: string
  phoneNumbers: string[]
}

export type Likes = {
  userId: string
  tileIds?: string[]
  subtileIds?: string[]
  thoughtIds?: string[]
}

export type Thought = {
  id: string
  tileId: string
  userId: string
  status: ThoughtStatus
  muxMetadata?: MuxMetadata
  bucket: string
  uploadPath: string
  socialUrl?: string
}

export enum ThoughtStatus {
  Uploading = 'uploading',
  Processing = 'processing',
  NeedsReview = 'needsReview',
  Approved = 'approved',
  Rejected = 'rejected',
}

export type MuxMetadata = {
  assetId: string
  playbackId: string
  playbackUrl: string
  thumbnailUrl: string
  muxThumbnailUrl: string
  animatedGifUrl: string
}

export type Preference = {
  id: string
}

export type Post = {
  id: string
  postDate: string
  score: number
  pinnedIndex: number
  labels?: string[]
  categories?: string[]
  type: PostType
  read: boolean
  tapped: boolean
  lastReadDate?: Date
}

export enum PostType {
  Tile = 'tile',
  Wurd = 'wurd',
  Baw = 'baw',
  FeaturedThoughts = 'featured_thoughts',
  TikTokVideo = 'tiktok_video',
  TikTokSocial = 'tiktok_social',
  IGSocial = 'ig_social',
  Shop = 'shop',
  Join = 'join_cf',
  EarnRewards = 'earn_rewards',
}

export type TilePost = Post & {
  tile: LightTile
  subtileCount: number
  unreadSubtileCount: number
  liked: boolean
}

export type TikTokVideoPost = Post & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tiktok: any
}

export type LinkPost = Post & {
  title: string
  href: string
}

export type Tile = {
  id: string
  source: string
  sourceAction?: string
  sourceImage?: string
  sourceUrl: string
  bullets: string
  developing: boolean
  notificationBlast: boolean
  title: string
  slug: string
  emjoi?: string
  subtileLastAddedAt: string
  imageUrls: ImageItem[]
  sharingImageUrls: ImageItem[]
  labels?: string[]
  categories?: string[]
  subtiles: Subtile[]
  subtileIds: string[]
  featuredThoughtIds?: string[]
  thoughts?: Thought[]
  thoughtIds?: string[]
  similarTileIds?: string[]
  similarTiles?: LightTile[]
  pinnedIndex: number
  summary: string
  shareUrl?: string
  takes: TUserTakes
  takesPrompt: string
  question: string
  answer: string
  wrongAnswers: string[]
}

export type LightTile = {
  id: string
  title: string
  slug: string
  imageUrls: ImageItem[]
  subtileLastAddedAt: string
  summary: string
  developing: boolean
}

export type Subtile = {
  id: string
  status: string
  title: string
  summary?: string
  sourceUrl: string
  source?: string
  sourceAction?: string
  sourceImage?: string
  imageUrls: ImageItem[]
  sharingImageUrls: ImageItem[]
}

export type ImageItem = {
  aspectRatio: string
  url: string
}

export enum AspectRatio {
  _19_5x9 = '19.5x9',
  _4x3 = '4x3',
  _1x2 = '1x2',
  _2x1 = '2x1',
  developing = 'developing',
}

export type Category = {
  id: string
  order: number
  title: string
  slug: string
  emojiImageUrl: string
  color: string
}

////////////////////////////////////////////////////////////////////////
// Sanity Types
//
// These types represent the schema of the types in Sanity and should
// be used when creating or reading data from the Sanity SDK
////////////////////////////////////////////////////////////////////////

export type SanityAsset = {
  _type: string
  asset: {
    _type: string
    _ref: string
  }
}

export type SanityThought = {
  _id: string
  _type: string
  title: string
  tile: SanityReference
  userId: string
  status: string
  firstPublishedAt?: string
  approvedRejectedDate?: string
  muxMetadata: SanityMuxMetadata
  labels?: SanityTag[]
}

export type SanityReference = {
  _key?: string
  _ref: string
  _type: string
  _weak: boolean
}

export type SanityTile = {
  _id: string
  _type: string
  title: string
  slug: SanitySlug
  status: string
  publishDate?: string
  firstPublishedAt?: string
  subtileLastAddedAt?: string
  owner: string
  image19_5x9: SanityAsset
  image4x3?: SanityAsset
  image1x2?: SanityAsset
  image2x1?: SanityAsset
  subtiles?: SanityReference[]
  featuredThoughts?: SanityReference[]
  featuredRelatedTiles?: SanityReference[]
  labels?: SanityTag[]
  sharingImage19_5x9Url?: string
  isFromMigration?: boolean
  reversedPriority?: number
}

export type SanitySubtile = {
  _id: string
  _type: string
  tile?: SanityReference
  title: string
  status: string
  publishDate?: string
  firstPublishedAt?: string
  sourceUrl: string
  source: string
  summary?: string
  image19_5x9: SanityAsset
  labels?: SanityTag[]
  sharingImage19_5x9Url?: string
}

export type SanitySlug = {
  _type: string
  current: string
}

export type SanityTag = {
  label: string
  value: string
}

export type SanityCategoryShelf = {
  _id: string
  _type: string
  categories: SanityCategory[]
}

export type SanityCategory = {
  _id: string
  _type: string
  title: string
  slug: SanitySlug
  color: string
  route: string
  emojiImageUrl: string
}

export type SanityTileShelf = {
  _id: string
  _type: string
  tiles: SanityReference[]
}

export type SanityThoughtShelf = {
  _id: string
  _type: string
  thoughts: SanityReference[]
}

export type SanityVideo = {
  _id: string
  _type: string
  title: string
  tile: SanityTile
  status: string
  mp4: SanityAsset
  muxMetadata?: SanityMuxMetadata
  labels?: SanityTag[]
  socialUrl?: string
}

export type SanityMuxMetadata = {
  assetId: string
  playbackId: string
  playbackUrl: string
  thumbnailUrl: string
  muxThumbnailUrl: string
  animatedGifUrl: string
}

export type SanityFeaturedThoughts = {
  _id: string
  muxMetadata: SanityMuxMetadata
  tile: {
    slug: {
      current: string
    }
    title: string
  }
}

export type UserTake = {
  userId: string
  takeId: number
  updatedAt: Date
}

export type UserTakeForUser = {
  takeId: number
  tileId: string
  modifiedAt: Date
}

export type QuizQuestion = {
  question: string
  answer: string
  allAnswers: string[]
  img: string
}

export type CompletePageProps = {
  todayScore: number
  userHighScore: number
  topScore: number
  newStreak: number
}
