import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/navigation'
import Button from '~/components/button'

export default function Error({ clearError }: { clearError: () => void }) {
  const router = useRouter()

  const handleGoBack = () => {
    clearError()
    router.push('/')
  }

  return (
    <div className="relative bg-background-primary text-content-primary w-full dynamic-screen-min-height flex flex-col justify-center items-center gap-4 px-4">
      <div className="absolute z-10 top-8 left-8">
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            e.stopPropagation()
            handleGoBack()
          }}
          className="bg-background-inverse_primary h-9 w-9 rounded-full flex justify-center items-center"
        >
          <ChevronLeftIcon className="h-6 w-6 text-content-inverse_terniary" />
        </div>
      </div>
      <h1 className="heading_xx_large">500</h1>
      <p className="paragraph_medium w-2/3 text-center">there was an error, please try again later.</p>
      <Button content="return to feed" onClick={handleGoBack} />
    </div>
  )
}
