'use client'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import React from 'react'
import ErrorView from './500'

const bugsnagAPIKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
const appVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'dev'
const releaseStage = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'

if (typeof window !== 'undefined' && bugsnagAPIKey) {
  Bugsnag.start({
    apiKey: bugsnagAPIKey,
    collectUserIp: false,
    enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
    maxBreadcrumbs: 100,
    generateAnonymousId: false,
    enabledReleaseStages: ['production', 'development', 'stage'],
    plugins: [new BugsnagPluginReact()],
    appVersion,
    releaseStage,
    logger: null,
    metadata: { appVersion: appVersion, releaseStage: releaseStage },
  })
}

const plugin = Bugsnag.getPlugin('react')

const BSErrorBoundary: BugsnagErrorBoundary | undefined = plugin?.createErrorBoundary(React)

export function ErrorBoundary({ children }: { children: any }) {
  if (BSErrorBoundary) {
    return <BSErrorBoundary FallbackComponent={ErrorView}>{children}</BSErrorBoundary>
  } else {
    return <>{children}</>
  }
}

export default Bugsnag
