import { collection, doc, getDoc, getDocs, query, orderBy, where } from 'firebase/firestore'
import { db } from '~/lib/firebase'
import { Category, Tile } from '~/types'
import { QuerySnapshot } from '@firebase/firestore'

import algoliasearch from 'algoliasearch'

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API || '',
)

interface PostQuery {
  page: number
  limit: number
  userId?: string
  feedTileIds: string[]
  developing?: boolean
}

export const getTileById = async (tileId: string): Promise<Tile | null> => {
  const tilesCollection = collection(db, 'tiles')
  const tile = await getDoc(doc(tilesCollection, tileId))

  return tile.data() as Tile | null
}

export const getCategories = async (): Promise<Category[]> => {
  const q = query(collection(db, 'categories'), orderBy('order', 'asc'))
  const categoryDocs = await getDocs(q)

  // filter out categories that have no emoji image
  return categoryDocs.docs.flatMap((categoryDoc) => {
    const category = categoryDoc.data() as Category
    return category.emojiImageUrl ? category : []
  })
}

export const getTileBySlug = async (tileSlug: string): Promise<Tile | null> => {
  const tilesCollection = collection(db, 'tiles')
  const q = query(tilesCollection, where('slug', '==', tileSlug))
  const tileSnapshot = await getDocs(q)

  if (!tileSnapshot.docs[0]) return null

  const tile = tileSnapshot.docs[0].data() as Tile

  return tile
}

export const getAllTilesByIds = async (tileIds: string[]): Promise<Tile[]> => {
  //Note: firestore IN clause only allows up to 10 parameters so we have to break them up and call them separately
  const queries = [] as Promise<QuerySnapshot>[]
  tileIds.forEach((tileId) => {
    const tilesCollection = collection(db, 'tiles')
    const q = query(tilesCollection, where('id', '==', tileId))
    queries.push(getDocs(q))
  })

  const responses = await Promise.all(queries)

  let tiles = [] as Tile[]
  responses.forEach((response) => {
    const tileData = response.docs.map((tileSnapshot) => {
      return tileSnapshot.data() as Tile[]
    })

    tiles = Array.prototype.concat(tiles, tileData)
  })
  return tiles
}

export const getAllShadedTilesByIds = async (tileIds: string[]): Promise<Tile[]> => {
  const queries = [] as Promise<QuerySnapshot>[]
  tileIds.forEach((tileId) => {
    const tilesCollection = collection(db, 'tiles')
    const q = query(tilesCollection, where('id', '==', tileId), where('developing', '!=', true))
    queries.push(getDocs(q))
  })

  const responses = await Promise.all(queries)

  let tiles = [] as Tile[]
  responses.forEach((response) => {
    const tileData = response.docs.map((tileSnapshot) => {
      return tileSnapshot.data() as Tile[]
    })

    tiles = Array.prototype.concat(tiles, tileData)
  })
  return tiles
}

// function for querying for posts from firestore
export const getFeedTiles = async ({ page, limit, feedTileIds }: PostQuery): Promise<Tile[]> => {
  const pagedTileIds = feedTileIds.slice(limit * (page - 1), limit * page)
  const tiles = await getAllTilesByIds(pagedTileIds)
  return tiles
}

export const getCategoryTilePostsIds = async ({
  userId,
  categorySlug,
}: {
  userId: string | undefined
  categorySlug: string
}) => {
  const ids = await getAlgoliaRankedTileIds({ userId, categorySlug })
  return ids
}

export const getAlgoliaRankedTileIds = async ({
  userId,
  categorySlug,
}: {
  userId?: string | undefined
  categorySlug?: string | undefined
}) => {
  const index = searchClient.initIndex('tiles_personalized')
  const [res1, res2] = await Promise.all([
    index.search('', {
      enablePersonalization: true,
      hitsPerPage: 1000,
      page: 0,
      attributesToRetrieve: [],
      attributesToHighlight: [],
      userToken: userId,
      filters: buildAlgoliaFilterString(categorySlug),
    }),
    index.search('', {
      enablePersonalization: true,
      hitsPerPage: 1000,
      page: 1,
      attributesToRetrieve: [],
      attributesToHighlight: [],
      userToken: userId,
      filters: buildAlgoliaFilterString(categorySlug),
    }),
  ])

  const algoliaTileRankings_1 = res1.hits
  const algoliaTileRankings_2 = res2.hits
  const tileRankIds_1 = algoliaTileRankings_1.map((hit) => hit.objectID)
  const tileRankIds_2 = algoliaTileRankings_2.map((hit) => hit.objectID)
  const tileRankIds = tileRankIds_1.concat(tileRankIds_2)

  return tileRankIds
}

const buildAlgoliaFilterString = (categorySlug: string | undefined) => {
  const queryParts = []
  queryParts.push(`developing = 0`)
  if (categorySlug) {
    queryParts.push(`categories:${categorySlug}`)
  }

  const query = queryParts.join(' AND ')

  return query
}
