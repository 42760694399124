import { RedeemableItem, SanityCategory } from '~/types'

export const WEB_PAGE_BASE_URL = 'https://cf.me'
export const MOBILE_APP_STORE_PAGE_URL =
  'https://apps.apple.com/app/apple-store/id1581205588?pt=123377579&ct=web_navbar&mt=8'
export const MOBILE_PLAY_STORE_PAGE_URL = 'https://play.google.com/store/apps/details?id=me.cf.twa'

export const SLIDE_IN_OUT_DURATION = 0.3

export const STATIC_PAGES_NAVBAR_ITEMS = [
  { id: 0, title: 'about', routeParam: 'about', color: 'grape', route: '/about' },
  { id: 1, title: 'terms', routeParam: 'terms', color: 'grape', route: '/terms' },
  { id: 2, title: 'privacy', routeParam: 'privacy', color: 'grape', route: '/privacy' },
  { id: 3, title: 'support', routeParam: 'support', color: 'grape', route: '/support' },
  { id: 4, title: 'careers', routeParam: 'careeers', color: 'grape', route: '/careers' },
]

export const SHADOW_COLORS = [
  'red',
  'grape',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
]

export const HOME_CATEGORY: SanityCategory = {
  _id: 'home',
  _type: 'category',
  route: '/',
  title: 'all.',
  slug: {
    _type: 'slug',
    current: '/',
  },
  color: '',
  emojiImageUrl: '',
}

export const SEARCH_CATEGORY: SanityCategory = {
  _id: 'search',
  _type: 'category',
  route: '/search',
  title: 'search.',
  slug: {
    _type: 'slug',
    current: '/search',
  },
  color: '',
  emojiImageUrl: '',
}

export const IMAGE_ASPECT_RATIOS = {
  '195X9': '19.5x9',
  '4X3': '4x3',
  '1X2': '1x2',
  '2X1': '2x1',
}

export const SEARCH_PAGE_IMAGE_ASPECT_RATIO = 0.5
export const ME_PAGE_IMAGE_ASPECT_RATIO = 0.5

export const HOME_FEED_POST_REQUEST_LIMIT = 24
export const TILES_PER_REQUEST_LIMIT = 24
export const MARQUEE_TILES_REQUEST_LIMIT = 20

export const GRID_REPEATING_UNIT = 18

export const CUSTOM_TILE_TYPE = {
  FEATURED_THOUGHTS: 'featured-thoughts',
  TIKTOK_TILE: 'tiktok-tile',
  WURD: 'wurd',
  BAW: 'baw',
  CF_ON_TIKTOK: 'cf-on-tiktok',
  CF_ON_INSTAGRAM: 'cf-on-instagram',
  SHOP: 'shop',
  JOIN_CF: 'join-cf',
  EARN_REWARDS: 'earn-rewards',
}

export const CF_SHOP = 'https://seeeff.com/collections/frontpage'
export const CF_WURD_GAME = 'https://shades.news/wurd/index.html'
export const CF_BAW_GAME = 'https://cf.me'
export const CF_ON_TIKTOK = 'https://tiktok.com/@sssshadessss'
export const CF_ON_INSTAGRAM = 'https://www.instagram.com/sssshadessss'
export const CF_EMAIL_US = 'hello@cf.me'
export const CF_CAREERS_EMAIL = 'careers@cf.me'

export const SHADES_ON_TIKTOK = 'https://tiktok.com/@sssshadessss'
export const SHADES_ON_INSTAGRAM = 'https://www.instagram.com/sssshadessss'
export const SHADES_ON_FACEBOOK = 'https://www.facebook.com/sssshadessss'
export const SHADES_ON_TWITTER = 'https://twitter.com/sssshadessss'
export const SHADES_ON_DISCORD = 'https://discord.gg/Gv2ePz3XTk'
export const SHADES_CONTENT_CREATOR = 'https://rf4q5xtm5g6.typeform.com/to/ZkM4m1IJ'
export const SHADES_GIVE_FEEDBACK =
  'https://docs.google.com/forms/d/e/1FAIpQLSf03wVsMQamvloKLTBn566MFI_0mQW7taRfOAnzia2DimrfMw/viewform?usp=sf_link'
export const SHADES_DAILY_NEWSLETTER =
  'https://docs.google.com/forms/d/e/1FAIpQLSePr4-95Rma5HBgCxfOuaE1OxIKrIz645nrjep3M8ZJVc_pkg/viewform?usp=sf_link'

export const STATIC_IMAGES = {
  PLACEHOLDER: '/static-images/placeholder.png',
  FAVICON_PLACEHOLDER: '/static-images/favicon_placeholder.png',
  SHADES_LOGO: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/shades_logo.png`,
  THOUGHTS_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/thoughts-thumb.png`,
  TIKTOK_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/tiktok-thumb.png`,
  WURD_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/wurd-thumb.png`,
  INSTAGRAM_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/instagram-thumb.png`,
  BAW_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/baw-thumb.png`,
  SHOP_THUMB: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/shop.png`,
  PLAY_BUTTON: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/play-button.png`,
  ABOUT_PAGE: {
    THINKING_MAN_EMOJI: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/thinking_man_emoji.png`,
    SLIDER_1_1: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_1_1.png`,
    SLIDER_1_2: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_1_2.png`,
    SLIDER_1_3: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_1_3.png`,
    SLIDER_1_4: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_1_4.png`,
    SLIDER_2_1: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_2_1.png`,
    SLIDER_2_2: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_2_2.png`,
    SLIDER_2_3: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_2_3.png`,
    SLIDER_2_4: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/about_slider_2_4.png`,
    HERO: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/cf_hero.png`,
    WHAT_WE_DO_GIRL: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/girl-with-oculus.png`,
    WHAT_WE_DO_TOUCH: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/about_page/touching-god.png`,
  },
  REFERRAL: {
    PURPLE_GEM: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/referral/purple_gem.png`,
    BLUE_GEM: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/referral/blue_gem.png`,
    PINK_GEM: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/referral/pink_gem.png`,
    GREEN_GEM: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/referral/green_gem.png`,
  },
  REDEEM_POINTS: {
    BUCKET: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/bucket.png`,
    IPAD: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/ipad.png`,
    KEYCHAIN: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/keychain.png`,
    PATCH: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/patch.png`,
    SET: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/set_boy.png`,
    STICKERS: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/stickers.png`,
    TOTE: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/tote.png`,
    WATER_BOTTLE: `https://${process.env.NEXT_PUBLIC_STATIC_IMAGE_BASE_URL}/redeem_points/water_bottle.png`,
  },
}

export const REFERRAL_KEYWORDS = {
  INVITE: 'invite',
  REFERRED_BY: 'referredBy',
  LOCALSTORAGE_KEY: 'cf_referred_by_code',
}

export const REDEEMABLE_ITEMS: RedeemableItem[] = [
  {
    title: 'stickers.',
    points: 300,
    image: STATIC_IMAGES.REDEEM_POINTS.STICKERS,
  },
  {
    title: 'keychain.',
    points: 500,
    image: STATIC_IMAGES.REDEEM_POINTS.KEYCHAIN,
  },
  {
    title: 'patch.',
    points: 1000,
    image: STATIC_IMAGES.REDEEM_POINTS.PATCH,
  },
  {
    title: 'tote.',
    points: 2500,
    image: STATIC_IMAGES.REDEEM_POINTS.TOTE,
  },
  {
    title: 'water bottle.',
    points: 2500,
    image: STATIC_IMAGES.REDEEM_POINTS.WATER_BOTTLE,
  },
  {
    title: 'bucket.',
    points: 5000,
    image: STATIC_IMAGES.REDEEM_POINTS.BUCKET,
  },
  {
    title: 'sweat set.',
    points: 10000,
    image: STATIC_IMAGES.REDEEM_POINTS.SET,
  },
  {
    title: 'ipad.',
    points: 100000,
    image: STATIC_IMAGES.REDEEM_POINTS.IPAD,
  },
]

export const SHADES_PAGE_BASE_TOP_MARGIN = 200

export const STATIC_MARKETING_PAGES = {
  ABOUT: 'https://shades.news/about',
  PRIVACY: 'https://shades.news/privacy',
  TERMS: 'https://shades.news/terms',
  SUPPORT: 'https://shades.news/support',
  DELETE: 'https://shades.news/delete',
}

export const SHADED_TILE_FAVICON_LIMIT = 5
