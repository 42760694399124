import Head from 'next/head'
import type { AppProps } from 'next/app'
import '~/styles/app.css'
import { Inter, Itim } from '@next/font/google'
import { PageWithLayout } from '~/types/page'
import Providers from '~/components/providers'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import { AppsFlyer, AFInit } from 'appsflyer-capacitor-plugin'
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'

if (Capacitor.isNativePlatform()) {
  // properly set the status bar on ios and android
  StatusBar.setStyle({ style: Style.Dark })
  if (Capacitor.getPlatform() === 'android') {
    StatusBar.setBackgroundColor({ color: '#000000' })
    StatusBar.setOverlaysWebView({ overlay: false })
  }

  const options: AFInit = {
    appID: process.env.NEXT_PUBLIC_IOS_APP_ID || '1582799434',
    devKey: 'Q6Mj6UDuy4DycBzWxZ7Q4b',
    isDebug: false,
    registerOnDeepLink: true,
    registerConversionListener: true,
  }

  AppsFlyer.initSDK(options)
}

const inter = Inter({
  variable: '--font-inter',
  display: 'optional',
})

const itim = Itim({
  variable: '--font-itim',
  weight: '400',
})

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const getLayout = (Component as PageWithLayout).getLayout || ((page: any) => page)

  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = new URL(event.url).pathname
      if (!slug) return
      router.push(slug)
    })
  }, [])

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="description" content="Shades" />
        <meta name="keywords" content="Shades" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
        <link rel="apple-touch-icon" href="/icons/icon-180x180.png"></link>
      </Head>
      <div className={`${inter.variable} ${itim.variable}`}>
        <Providers>{getLayout(<Component {...pageProps} />)}</Providers>
      </div>
    </>
  )
}

export default App
