'use client'

import Bugsnag from './bugsnag'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { auth, db, functions } from '~/lib/firebase'
import { User, Tile } from '~/types'
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { REFERRAL_KEYWORDS } from '~/lib/constants'
import { limit as qlimit } from '@firebase/firestore'
import { getAlgoliaRankedTileIds } from '~/lib/queries'
import { logAppsFlyerEvent } from '~/lib/appsFlyerEvents'
import { logFirebaseEvent } from '~/lib/firebase'
type UserState = {
  newUserEmail?: string
  setNewUserEmail: any
  user?: User
  setUser: any
  isLoading: boolean
  likedTileIds: string[]
  unlikeTile: any
  likeTile: any
  setFeedToDefault: any
  getUnreadSubtileCountForTile: (tile: Tile) => Promise<number>
  userFeedTileIds: string[]
  userFeedLoaded: boolean
}

export const UserContext = createContext<UserState | undefined>(undefined)

type IUserProvider = {
  children: ReactNode
}

export default function UserProvider({ children }: IUserProvider) {
  const [newUserEmail, setNewUserEmail] = useState<string>()
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [likedTileIds, setLikedTileIds] = useState<string[]>([])
  const [userFeedTileIds, setUserFeedTileIds] = useState<string[]>([])
  const [userFeedLoaded, setUserFeedLoaded] = useState<boolean>(false)

  const setFeedToDefault = async () => {
    const rankedTiledIds = await getAlgoliaRankedTileIds({ userId: user?.id })
    setUserFeedTileIds(rankedTiledIds)
  }

  const unlikeTile = async (tileId: string) => {
    const newLikedTiles = [...likedTileIds]
    const index = likedTileIds.indexOf(tileId)
    if (index > -1) {
      newLikedTiles.splice(index, 1)
      setLikedTileIds(newLikedTiles)
      const unlikePost = httpsCallable(functions, 'likes-unlike_tile')
      await unlikePost({ tileId })
    }
  }

  const likeTile = async (tileId: string) => {
    const newLikedTiles = [...likedTileIds]
    newLikedTiles.push(tileId)
    setLikedTileIds(newLikedTiles)
    const likePost = httpsCallable(functions, 'likes-like_tile')
    await likePost({ tileId })
  }

  const getUnreadSubtileCountForTile = async (tile: Tile) => {
    let unreadCount = 0
    if (tile.subtileIds) {
      const currentSubtileCount = tile.subtileIds.length

      if (user) {
        const q = query(
          collection(db, `users/${user.id}/tileTaps`),
          where('tileId', '==', tile.id),
          orderBy('tappedDate', 'desc'),
          qlimit(1),
        )
        const snapshot = await getDocs(q)
        const docs = snapshot.docs

        if (docs.length > 0) {
          const tappedRecord = docs[0].data()
          if (tappedRecord.subtileCountSnapshot < currentSubtileCount) {
            unreadCount = currentSubtileCount - tappedRecord.subtileCountSnapshot
          }
        }
      }
    }
    return unreadCount
  }

  useEffect(() => {
    if (user) {
      const getUserFeed = httpsCallable(functions, 'users-get_home_feed')
      getUserFeed().then((res) => {
        setUserFeedTileIds(res.data as string[])
      })
    }
  }, [user])

  useEffect(() => {
    userFeedTileIds.length > 0 && setUserFeedLoaded(true)
  }, [userFeedTileIds])

  useEffect(() => {
    // Listen for an authenticated user
    const unsubscriber = auth.onAuthStateChanged(async (firebaseUser) => {
      try {
        if (firebaseUser) {
          let userFromStore: User | undefined

          const userFromStoreRef = doc(db, 'users', firebaseUser.uid)
          const userDoc = await getDoc(userFromStoreRef)
          userFromStore = userDoc.data() as User | undefined

          if (!userFromStore) {
            const createUser = httpsCallable(functions, 'users-create_user')
            const createUserProps: Partial<User> = { email: newUserEmail }
            if (localStorage) {
              const referredBy = localStorage.getItem(REFERRAL_KEYWORDS.LOCALSTORAGE_KEY)
              if (referredBy) {
                createUserProps.referredBy = referredBy
              }
            }
            logAppsFlyerEvent('create_account')
            logFirebaseEvent('create_account')
            userFromStore = (await createUser(createUserProps)).data as User
            window._paq?.push(['trackEvent', 'Auth', 'created_account'])
            window._paq?.push(['trackEvent', 'Email', `subscribed_to_email`])
            window._paq?.push(['trackEvent', 'SMS', `subscribed_to_sms`])

            // now unset the new user email
            setNewUserEmail(undefined)
          }

          if (!userFromStore) throw new Error(`logged in user is mssing a user record in firestore ${firebaseUser.uid}`)

          // now that we have a logged in user (new or otherwise), we no longer need the stored referral code
          if (localStorage) {
            localStorage.removeItem(REFERRAL_KEYWORDS.LOCALSTORAGE_KEY)
          }

          setUser(userFromStore)
        } else {
          setUser(undefined)
        }
      } catch (error: any) {
        Bugsnag.notify(error)
      } finally {
        setIsLoading(false)
      }
    })

    // Unsubscribe auth listener on unmount
    return (): void => unsubscriber()
  }, [newUserEmail])

  useEffect(() => {
    if (user) {
      const likesCollection = collection(db, `users/${user.id}/likes`)
      const q = query(likesCollection)
      getDocs(q).then((snapshot) => {
        const docs = snapshot.docs
        if (snapshot.docs.length > 0) {
          const doc = docs[0].data()
          const { tileIds } = doc
          if (tileIds) {
            setLikedTileIds(tileIds)
          }
        } else {
          setLikedTileIds([])
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const calculateEligibleTiles = httpsCallable(functions, 'users-calculate_eligible_tiles')
      calculateEligibleTiles().catch((error: any) => {
        const constructedError = new Error(`Caluclate Eligible Tiles Error :${error.message}`)
        Bugsnag.notify(constructedError)
      })
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userFeedLoaded,
        isLoading,
        newUserEmail,
        setNewUserEmail,
        likedTileIds,
        likeTile,
        unlikeTile,
        getUnreadSubtileCountForTile,
        userFeedTileIds,
        setFeedToDefault,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserState = (): UserState => {
  const userState = useContext(UserContext)
  if (!userState) throw new Error("Can't call useUser before the user context is loaded")
  return userState
}

export const useUser = (): User => {
  const userState = useContext(UserContext)
  if (!userState || !userState.user) throw new Error("Can't call useUser before the user context is loaded")
  return userState.user
}

export const useOptionalUser = (): User | undefined => {
  const userState = useContext(UserContext)
  return userState?.user
}
