'use client'

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { Gestures } from '~/types'

interface IGestureContext {
  homeFeedVerticalGestureState: Gestures
  setHomeFeedVerticalGestureState: Dispatch<SetStateAction<Gestures>>
  isHomeFeedHorizontalGestureAllowedOnAndroid: boolean
  setIsHomeFeedHorizontalGestureAllowedOnAndroid: Dispatch<SetStateAction<boolean>>
}

export enum GestureDirection {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export const GestureContext = createContext<IGestureContext | null>(null)

export default function GestureProvider({ children }: { children: ReactNode }) {
  const [homeFeedVerticalGestureState, setHomeFeedVerticalGestureState] = useState<Gestures>(Gestures.HOMEFEED)
  const [isHomeFeedHorizontalGestureAllowedOnAndroid, setIsHomeFeedHorizontalGestureAllowedOnAndroid] = useState(false)

  const context = {
    homeFeedVerticalGestureState,
    setHomeFeedVerticalGestureState,
    isHomeFeedHorizontalGestureAllowedOnAndroid,
    setIsHomeFeedHorizontalGestureAllowedOnAndroid,
  }

  return <GestureContext.Provider value={context}>{children}</GestureContext.Provider>
}

export const useHomeFeedVerticalGestureState = () => {
  const gestureState = useContext(GestureContext)
  if (!gestureState) throw new Error("Can't call useHomeFeedVerticalGestureState before the context is loaded")
  return {
    gestureState: gestureState.homeFeedVerticalGestureState,
    setGestureState: gestureState.setHomeFeedVerticalGestureState,
  }
}

export const useIsHomeFeedHorizontalGestureAllowedOnAndroidState = () => {
  const gestureState = useContext(GestureContext)
  if (!gestureState)
    throw new Error("Can't call useIsHomeFeedHorizontalGestureAllowedOnAndroidState before the context is loaded")
  return {
    isHomeFeedHorizontalGestureAllowedOnAndroid: gestureState.isHomeFeedHorizontalGestureAllowedOnAndroid,
    setIsHomeFeedHorizontalGestureAllowedOnAndroid: gestureState.setIsHomeFeedHorizontalGestureAllowedOnAndroid,
  }
}
