'use client'

import { usePathname } from 'next/navigation'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { TabItems, TabNavigation, TabState, TTab } from '~/types'
import {
  GamesActiveIcon,
  GamesIcon,
  HomeActiveIcon,
  HomeIcon,
  ProfileActiveIcon,
  ProfileIcon,
  SearchActiveIcon,
  SearchIcon,
  SettingsActiveIcon,
  SettingsIcon,
} from './icons'

interface ITabBarContext {
  tabState: TTab[]
  setActiveTab: (activeTab: string) => void
}

export const TabBarContext = createContext<ITabBarContext | null>(null)

const initialTabBarState = [
  {
    id: 0,
    title: TabItems.HOME,
    state: TabState.ACTIVE,
    navigate: TabNavigation.FEED,
    icon: {
      active: <HomeActiveIcon className="h6 w-6" />,
      inactive: <HomeIcon className="h-6 w-6" />,
    },
  },
  {
    id: 1,
    title: TabItems.SEARCH,
    state: TabState.INACTIVE,
    navigate: TabNavigation.SEARCH,
    icon: {
      active: <SearchActiveIcon className="h6 w-6" />,
      inactive: <SearchIcon className="h-6 w-6" />,
    },
  },
  {
    id: 2,
    title: TabItems.ME,
    state: TabState.INACTIVE,
    navigate: TabNavigation.ME,
    icon: {
      active: <ProfileActiveIcon className="h6 w-6" />,
      inactive: <ProfileIcon className="h-6 w-6" />,
    },
  },
  {
    id: 3,
    title: TabItems.GAMES,
    state: TabState.INACTIVE,
    navigate: TabNavigation.GAMES,
    icon: {
      active: <GamesActiveIcon className="h6 w-6" />,
      inactive: <GamesIcon className="h-6 w-6" />,
    },
  },
  {
    id: 4,
    title: TabItems.SETTINGS,
    state: TabState.INACTIVE,
    navigate: TabNavigation.SETTINGS,
    icon: {
      active: <SettingsActiveIcon className="h6 w-6" />,
      inactive: <SettingsIcon className="h-6 w-6" />,
    },
  },
]

export default function TabBarProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  const [tabState, setTabState] = useState<TTab[]>(initialTabBarState)

  useEffect(() => {
    if (pathname?.includes('search')) {
      setActiveTab(TabItems.SEARCH)
    } else if (pathname?.includes('settings' || 'profile' || 'login' || 'register')) {
      setActiveTab(TabItems.SETTINGS)
    } else if (pathname?.includes('games')) {
      setActiveTab(TabItems.GAMES)
    } else if (pathname?.includes('me')) {
      setActiveTab(TabItems.ME)
    } else {
      setActiveTab(TabItems.HOME)
    }
  }, [pathname])

  const setActiveTab = (activeTab: string) =>
    setTabState(
      initialTabBarState.map((tab: TTab) => {
        if (tab.title === activeTab) {
          return { ...tab, state: TabState.ACTIVE }
        }
        if (tab.state === TabState.ACTIVE) {
          return { ...tab, state: TabState.INACTIVE }
        }
        return { ...tab }
      }),
    )

  const context = {
    tabState,
    setActiveTab,
  }

  return <TabBarContext.Provider value={context}>{children}</TabBarContext.Provider>
}

export const useTabState = (): ITabBarContext => {
  const tabBarState = useContext(TabBarContext)
  if (!tabBarState) throw new Error("Can't call useTabState before the context is loaded")
  return tabBarState
}
