import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import { getAuth, connectAuthEmulator, initializeAuth, Auth, indexedDBLocalPersistence } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { Capacitor } from '@capacitor/core'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

// if none create one
let analytics: any
const firebaseApp = initializeApp(firebaseConfig)
if (firebaseApp.name && typeof window !== 'undefined') {
  analytics = getAnalytics(firebaseApp)
}

export const db = getFirestore(firebaseApp)
export const realtimeDb = getDatabase(firebaseApp)
export const auth = whichAuth()
export const functions = getFunctions(firebaseApp)
export const storage = getStorage(firebaseApp)

function whichAuth(): Auth {
  let auth: Auth
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    })
  } else {
    auth = getAuth(firebaseApp)
  }
  return auth
}

if (process.env.NEXT_PUBLIC_USE_EMULATORS === 'true') {
  try {
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
    connectFunctionsEmulator(functions, 'localhost', 5001)
    connectFirestoreEmulator(db, 'localhost', 8080)
  } catch {
    // this code throws if it tries to run more than once
  }
}

export const logFirebaseEvent = async (eventName: string, userId: string | null | undefined = null, params = {}) => {
  try {
    if (Capacitor.isNativePlatform()) {
      if (userId) {
        await FirebaseAnalytics.setUserId({
          userId: userId,
        })
      }
      await FirebaseAnalytics.logEvent({
        name: eventName,
        params,
      })
    } else {
      if (userId) {
        setUserId(analytics, userId)
      }
      await logEvent(analytics, eventName, params)
    }
  } catch (err) {
    alert(err)
  }
}
