import type { MDXRemoteSerializeResult } from 'next-mdx-remote'
import { ReactElement } from 'react'
import { ImageItem, LightTile, SanityMuxMetadata } from './types'

export * from './types'

export type PageData = {
  generatedAt: string
  revalidatesAt?: string
  url: string
}

export type ContentConfig = {
  filename: string
}

export type ContentPage = {
  _pageData?: PageData
  source: MDXRemoteSerializeResult
}

export type HeadConfig = {
  description?: string
  imageUrl?: string
  url?: string
  title?: string
}

export type UserMedia = {
  id: string
  media_cdn_url: string
  thumbnail_cdn_url: string
}

export enum Viewport {
  widescreen = 'widescreen',
  mobile = 'mobile',
}

export enum ModalViewMode {
  transition = 'transition',
  render = 'render',
}

export type SearchResults = {
  hits: LightTile[]
  hasMore: boolean
  refineNext: () => void
}

export enum UserCredentialsFormType {
  LOGIN = 'LOGIN',
  JOIN = 'JOIN',
}

export type RedeemableItem = {
  title: string
  points: number
  image: string
}

export type SanityTiktokTile = {
  _id: string
  tile: {
    slug: {
      current: string
    }
    title: string
  }
  muxMetadata?: SanityMuxMetadata
  tiktokUrl: string
  imageUrl: string
}

export enum OpenPositionTypeEnum {
  INTERNSHIP = 'INTERNSHIP',
  FULL_TIME = 'FULL_TIME',
}

export type OpenPosition = {
  title: string
  type: OpenPositionTypeEnum
  details: ReactElement
}

export enum TileTypeEnum {
  SHADED = 'shaded',
  DEVELOPING = 'developing',
  SHADED_DETAIL = 'shaded-detail',
}

export enum GamesList {
  WURD = 'WURD',
  BAW = 'BAW',
  QUIZ_ME = 'QUIZ_ME',
}

export enum TabItems {
  HOME = 'home',
  SEARCH = 'search',
  ME = 'me',
  GAMES = 'games',
  SETTINGS = 'settings',
}

export enum TabState {
  ACTIVE,
  INACTIVE,
}

export enum TabNavigation {
  FEED = '/',
  SEARCH = '/search',
  ME = '/me',
  GAMES = '/games',
  SETTINGS = '/settings',
}

export type TTab = {
  id: number
  title: TabItems
  state: TabState
  navigate: TabNavigation
  icon: {
    active: ReactElement
    inactive: ReactElement
  }
}

export enum UserPreferences {
  INTERESTING = 'INTERESTING',
  USER_TAKES = 'USER_TAKES',
}

export enum UserTutorials {
  HOMEFEED_VERTICAL_SWIPE_TUTORIAL = 'HOMEFEED_VERTICAL_SWIPE_TUTORIAL',
  SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL = 'SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL',
  DEVELOPING_TILE_EXPANSION_TUTORIAL = 'DEVELOPING_TILE_EXPANSION_TUTORIAL',
  SUBTILE_EXPANSION_TUTORIAL = 'SUBTILE_EXPANSION_TUTORIAL',
}

export enum Gestures {
  HOMEFEED = 'HOMEFEED',
  SWIPABLE_SHADED_TILE = 'SWIPABLE_SHADED_TILE',
}

export enum SearchResultsPageType {
  CATEGORY = 'category',
  SEARCH = 'search',
}

export type TUserTakes = {
  id: number
  imageUrl: string
  title: string
}[]

export type UserTakesWithTile = {
  takeId: number
  takes: TUserTakes
  takesPrompt: string
  tileId: string
  title: string
  imageUrls: ImageItem[]
  slug: string
}

export enum RenderedShadedTileType {
  HOMEFEED_COVER = 'HOMEFEED_COVER',
  SHADED_TILE_DETAILS = 'SHADED_TILE_DETAILS',
}

// QUIZ ME GAME TYPES

export enum QUIZ_ME_GAME_STATE {
  INITIATE = 'Initiate',
  COUNTDOWN = 'Countdown',
  DELAY = 'Delay',
  PAUSE = 'Pause',
  NOTIME = 'NoTime',
  NEXT_QUESTION = 'NextQuestion',
}

export enum QUIZ_ME_ANSWER_BUTTON {
  UNCLICKED = 'bg-black border-brand-quaternary',
  CLICKED = 'bg-brand-quaternary border-brand-quaternary',
  CORRECT = 'bg-status-success border-brand-terniary',
  INCORRECT = 'bg-[#AF3E2F] border-[#FF6464]',
}

export enum QUIZ_ME_ANSWER_STATUS {
  UNANSWERED = 'transparent',
  CURRENT = 'fill-brand-quaternary',
  CORRECT = 'fill-quizme-correct',
  INCORRECT = 'fill-quizme-incorrect',
}

export enum NavigationStackBase {
  HOMEFEED = 'HOMEFEED',
  ME = 'ME',
  SEARCH = 'SEARCH',
}

export enum XBUTTON_STATUS {
  UNCLICKED = 'fill-white',
  CLICKED = 'fill-brand-quaternary',
  EXIT = 'fill-brand-quaternary',
}

export enum LEAVE_QUIZ_STATE {
  DONE = 'done',
  COMPLETE = 'complete',
  QUIT = 'quit',
}
