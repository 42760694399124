'use client'

import { ErrorBoundary } from '~/components/bugsnag'
import UserProvider from '~/components/userProvider'
import ApplicationConfigsProvider from './applicationConfigsProvider'
import GestureProvider from './gestureProvider'
import TabBarProvider from './tabBarProvider'
import NavigationStackProvider from './tiles/navigationStackProvider'

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ErrorBoundary>
        <UserProvider>
          <ApplicationConfigsProvider>
            <GestureProvider>
              <TabBarProvider>
                <NavigationStackProvider>{children}</NavigationStackProvider>
              </TabBarProvider>
            </GestureProvider>
          </ApplicationConfigsProvider>
        </UserProvider>
      </ErrorBoundary>
    </>
  )
}
