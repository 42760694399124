'use client'

import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { NavigationStackBase, Tile } from '~/types'

interface INavigationStackContext {
  shouldRenderVisitedTilesSection: boolean
  shouldPushToVisitedTiles: boolean
  setShouldPushToVisitedTiles: (arg: boolean) => void
  shouldAnimateInShadedTileCover: boolean
  setShouldAnimateInShadedTileCover: (arg: boolean) => void
  visitedTiles: Tile[]
  pushToVisitedTiles: (tile: Tile) => void
  popFromVisitedTiles: () => void
  resetVisitedTilesStack: () => void
}

export const NavigationStackContext = createContext<INavigationStackContext | null>(null)

export default function NavigationStackProvider({ children }: { children: ReactNode }) {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [navigationStackBase, setNavigationStackBase] = useState<NavigationStackBase>(NavigationStackBase.HOMEFEED)
  const [shouldPushToVisitedTiles, setShouldPushToVisitedTiles] = useState(true)
  const [shouldRenderVisitedTilesSection, setShouldRenderVisitedTilesSection] = useState(false)
  const [shouldAnimateInShadedTileCover, setShouldAnimateInShadedTileCover] = useState(false)
  const [visitedTiles, setVisitedTiles] = useState<Tile[]>([])
  const [hasLoaded, setHasLoaded] = useState(false)

  const handleSetNavigationStackBase = () => {
    if (pathname?.includes('/me')) setNavigationStackBase(NavigationStackBase.ME)
    else if (pathname?.includes('/search')) setNavigationStackBase(NavigationStackBase.SEARCH)
    else setNavigationStackBase(NavigationStackBase.HOMEFEED)
  }

  useEffect(() => {
    if (!hasLoaded) setHasLoaded(true)
    if (pathname === '/' || pathname === '/me' || pathname === '/search') setVisitedTiles([])
    if (shouldAnimateInShadedTileCover) setShouldAnimateInShadedTileCover(false)

    handleSetNavigationStackBase()
  }, [pathname])

  useEffect(() => {
    if (hasLoaded) {
      if (visitedTiles.length > 0) {
        setShouldRenderVisitedTilesSection(true)

        handleSetNavigationStackBase()
      } else {
        setShouldRenderVisitedTilesSection(false)
        if (pathname?.includes('/me')) {
          router.replace('/me')
        } else if (pathname?.includes('/search')) {
          const type = searchParams.get('t')
          const query = searchParams.get('q')
          router.replace(`/search?t=${type}&q=${query}`)
        } else {
          router.replace('/')
        }
      }
    }

    // eslint-disable-next-line
  }, [visitedTiles.length])

  useEffect(() => {
    if (!shouldPushToVisitedTiles) {
      if (visitedTiles.length > 0) {
        if (navigationStackBase === NavigationStackBase.ME) {
          router.replace(`/me/tiles/${visitedTiles[visitedTiles.length - 1].slug}`)
        } else if (navigationStackBase === NavigationStackBase.SEARCH) {
          const type = searchParams.get('t')
          const query = searchParams.get('q')
          router.replace(`/search/tiles/${visitedTiles[visitedTiles.length - 1].slug}?t=${type}&q=${query}`)
        } else if (navigationStackBase === NavigationStackBase.HOMEFEED) {
          router.replace(`/tiles/${visitedTiles[visitedTiles.length - 1].slug}`)
        }
      } else {
        setShouldPushToVisitedTiles(true)
      }
    }
    // eslint-disable-next-line
  }, [shouldPushToVisitedTiles])

  const pushToVisitedTiles = (tile: Tile) => {
    setVisitedTiles((prevState) => [...prevState, tile])
  }

  const popFromVisitedTiles = () => {
    setVisitedTiles(visitedTiles.slice(0, -1))
    setShouldPushToVisitedTiles(false)
  }

  const resetVisitedTilesStack = () => {
    if (visitedTiles.length < 1) return
    if (visitedTiles.length > 1) {
      setVisitedTiles((prevState) => [prevState[prevState.length - 1]])
    }
    setShouldAnimateInShadedTileCover(true)
  }

  const context = {
    shouldRenderVisitedTilesSection,
    shouldPushToVisitedTiles,
    setShouldPushToVisitedTiles,
    shouldAnimateInShadedTileCover,
    setShouldAnimateInShadedTileCover,
    visitedTiles,
    pushToVisitedTiles,
    popFromVisitedTiles,
    resetVisitedTilesStack,
  }

  return <NavigationStackContext.Provider value={context}>{children}</NavigationStackContext.Provider>
}

export const useNavigationStackState = (): INavigationStackContext => {
  const navigationStackState = useContext(NavigationStackContext)
  if (!navigationStackState) throw new Error("Can't call useNavigationStackState before the context is loaded")
  return navigationStackState
}
