import { AppsFlyer } from 'appsflyer-capacitor-plugin'
import { Capacitor } from '@capacitor/core'
export const logAppsFlyerEvent = async (eventName: string, params = {}) => {
  if (Capacitor.isNativePlatform()) {
    await AppsFlyer.logEvent({
      eventName,
      eventValue: params,
    })
  }
}
