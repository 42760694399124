import { LoadingIcon } from './icons'

type TButton = {
  type?: 'button' | 'submit' | 'reset'
  content: string
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

const Button = ({ type = 'button', content, isLoading = false, isDisabled = false, onClick }: TButton) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={`
        w-full h-14 flex justify-center items-center gap-1
        label_medium text-content-inverse_primary
        border border-stroke-opaque
        bg-brand-quaternary ${!isLoading ? 'disabled:bg-[#373737]' : ''}
      `}
    >
      {isLoading && <LoadingIcon className="h-6 w-6 animate-spin duration-1000" />}
      {content}
    </button>
  )
}

export default Button
